<template>
  <TransitionRoot appear :show="show" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-40">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/75 backdrop-filter backdrop-blur-xs" />
      </TransitionChild>

      <div class="fixed bottom-0 left-0 right-0 md:top-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center md:p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="relative w-full transform rounded-b-none md:rounded-b-xl rounded-xl bg-white text-left align-middle shadow-md transition-all max-w-full"
              :class="{
                'md:max-w-lg': size === 'default',
                'md:max-w-2xl': size === 'medium',
                'md:max-w-4xl': size === 'large',
                'overflow-hidden': !isOverflowShown,
              }"
            >
              <CloseButton
                v-if="!hideCloseIcon"
                @click="closeModal"
                class="hidden md:block absolute top-3 right-3 text-gray-400"
                :class="{ 'bg-white rounded-full': imageSrc }"
              />
              <img
                v-if="imageSrc"
                :src="imageSrc"
                class="w-full"
                :style="{ height: imageHeight }"
              />
              <div class="px-8 md:px-10 py-8">
                <div v-if="!hideIcon" class="mb-3.5">
                  <slot name="icon"></slot>
                </div>
                <DialogTitle v-if="title" as="h2" class="heading-2">
                  {{ title }}
                </DialogTitle>
                <p class="mt-2 empty:mt-0 text-base text-gray-500 font-normal">
                  <slot name="introText"></slot>
                </p>
                <div class="mt-8">
                  <slot></slot>
                </div>

                <div v-if="hasButton" class="mt-8 flex flex-wrap gap-3">
                  <TertiaryButton
                    v-if="secondaryButton?.text"
                    :busy="secondaryButton.busy"
                    :disabled="secondaryButton.disabled"
                    class="w-full md:w-auto"
                    @click="emit('secondaryClick')"
                  >
                    {{ secondaryButton.text }}
                  </TertiaryButton>
                  <component
                    :is="primaryButtonComponent"
                    v-if="primaryButton?.text"
                    :busy="primaryButton.busy"
                    :disabled="primaryButton.disabled"
                    class="w-full md:w-auto"
                    @click="emit('primaryClick')"
                  >
                    {{ primaryButton.text }}
                  </component>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { computed } from "vue"
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from "@headlessui/vue"
import PrimaryButton from "@/components/elements/buttons/PrimaryButton.vue"
import TertiaryButton from "@/components/elements/buttons/TertiaryButton.vue"
import CloseButton from "@/components/elements/buttons/CloseButton.vue"
import DangerButton from "@/components/elements/buttons/DangerButton.vue"
import SuccessButton from "../buttons/SuccessButton.vue"

const props = defineProps({
  show: Boolean,
  title: String,
  primaryButton: Object, // {text: 'Save', busy: false, disabled: false, type: 'submit', variant: 'danger'}
  secondaryButton: Object,
  size: {
    type: String,
    default: "default",
  },
  imageSrc: String,
  hideIcon: Boolean,
  hideCloseIcon: Boolean,
  isOverflowShown: Boolean, // to show overflow content like dropdown
})
const emit = defineEmits(["close", "primaryClick", "secondaryClick"])

const hasButton = computed(() => props.primaryButton?.text || props.secondaryButton?.text)
const primaryButtonComponent = computed(() => {
  if (!props.primaryButton?.text) return null
  switch (props.primaryButton.variant) {
    case "success":
      return SuccessButton
    case "danger":
      return DangerButton
    default:
      return PrimaryButton
  }
})

const imageHeight = computed(() => {
  switch (props.size) {
    case "medium":
      return "224px"
    case "large":
      return "254px"
    default:
      return "288px"
  }
})

function closeModal() {
  emit("close")
}
</script>
